import { Background, CMargin, Flex } from "../../../styles/CommonStyles";
import { LoginWrapper, LoginLabel, LoginBody } from "../../../styles/main/login";
import Input from "../../common/input/Input";
import Text from "../../../core/utils/Text";
import ActionButton from "../../common/buttons/ActionButton";
import useLogin, { INPUT_TYPES } from "../../../core/hooks/main/login/useLogin";
import useEnterFunction from "../../../core/hooks/common/useEnterFunction";


const LoginView = () => {

    const {
        step,
        inputData,
        inputStatus,
        loading,
        validAction,
        onInputValueChange,
        onSubmitClicked,
    } = useLogin()

    useEnterFunction(onSubmitClicked)

    return (
        <LoginWrapper>
            <LoginBody>
                <Background bg='primaryBg'>
                    <Flex fw>
                        <img
                            src={require('../../../assets/images/exfinit.png')}
                            alt={' '}
                            width='200px'
                        />
                    </Flex>
                    <CMargin margin='10px' />
                    <LoginLabel>
                        <Text tid={'phone-email-label'} />
                    </LoginLabel>
                    <Input
                        label='phone-email'
                        height='52px'
                        direction='ltr'
                        value={inputData.username}
                        valid={inputStatus.username}
                        onInputChange={(v) => onInputValueChange(v, INPUT_TYPES.USERNAME)}
                        error={inputData.username && 'invalid-mobile-email'}
                        disabled={step !== 1}
                        autoFocus={step === 1}
                    />
                    <LoginLabel>
                        <Text tid={'password'} />
                    </LoginLabel>
                    <Input
                        label='password'
                        height='52px'
                        direction='ltr'
                        value={inputData.password}
                        valid={inputStatus.password}
                        onInputChange={(v) => onInputValueChange(v, INPUT_TYPES.PASSWORD)}
                    />

                    <CMargin margin='20px' />
                    <ActionButton
                        active={validAction}
                        onClick={onSubmitClicked}
                        loading={loading}
                    >
                        <Text tid='submit' />
                    </ActionButton>
                </Background>
            </LoginBody>
        </LoginWrapper>
    )
}

export default LoginView
