import { useApiLogin, useRequestOtp, useVerify2fa, useVerifyOtp } from "../../../services/react-query/auth";
import { useEffect, useState } from "react";
import { browserName, browserVersion, osName, osVersion } from "react-device-detect";
import { validEmailRegex } from "../../../constants/common";
import { useQueryContext } from "../../../contexts/query";


const INPUT_TYPES = {
    USERNAME: 'userName',
    PASSWORD: 'password'
}


const useLogin = () => {

    const inputInitial = {
        username: '',
        type: null,
        password: '',
    }
    const [inputData, setInputData] = useState(inputInitial)

    const statusInitial = {
        username: null,
        password: null,
    }

    const [inputStatus, setInputStatus] = useState(statusInitial)


    const {
        isLoading: otpLoading,
        mutate
    } = useApiLogin()

    /**
     * step - 1) username, 2) code, 3) google
     */
    const [step, setStep] = useState(1)



    const [validAction, setValidAction] = useState(false)


    useEffect(() => {
        const valid =
            ((inputStatus.username && step === 1))
        // && inputStatus.captcha

        setValidAction(valid)

    }, [inputStatus, step])



    const onInputValueChange = (v, type) => {

        switch (type) {
            case INPUT_TYPES.USERNAME:
                validateUsername(v)
                setInputData(state => ({ ...state, username: v }))
                break
            case INPUT_TYPES.PASSWORD:
                setInputData(state => ({ ...state, password: v }))
                break
            default:
                break
        }
    }

    /**
     * validations
     */
    const validateUsername = (val) => {
        if (val && val.length > 0) {
            if (isNaN(val)) {
                // gmail
                const valid = validEmailRegex.test(val)
                setInputData(state => ({ ...state, type: 'email' }))
                setInputStatus(state => ({ ...state, username: valid }))
            } else {
                // mobile
                setInputData(state => ({ ...state, type: 'mobile' }))
                // eslint-disable-next-line
                if (!['۰', '0'].includes(val[0]) || val.length !== 11) {
                    setInputStatus(state => ({ ...state, username: false }))
                } else {
                    setInputStatus(state => ({ ...state, username: true }))
                }
            }
        }
        if (val.length === 0) {
            setInputData(state => ({ ...state, type: null }))
            setInputStatus(state => ({ ...state, username: null }))
        }
    }


    const { setToast } = useQueryContext()
    const onSubmitClicked = () => {
        if (validAction) {
            switch (step) {
                case 1:
                    mutate({ email: inputData.username, password: inputData.password })
                    break
                default:
                    break
            }
        } else {
            setToast({
                isError: true, show: true,
                message: 'fill-input-errors'
            })
        }
    }


    return {
        step,
        inputData,
        inputStatus,
        loading: otpLoading,
        validAction,
        onInputValueChange,
        onSubmitClicked,
    }
}

export default useLogin
export {
    INPUT_TYPES
}
