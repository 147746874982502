import { useAclContext } from "../../../contexts/acl";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../pages";
import { useCreateQuestions, useDefineAnswers, useDeleteQuestion, useDeleteResponse, useGetQuestions, useGetResultResponses, useStoreResultResponses, useUpdateQuestion } from "../../../services/react-query/questions";


const useQuestions = ({ fromDetail = false } = {}) => {

    const navigate = useNavigate()
    const { permissions } = useAclContext()
    const hasWriteAccess = permissions?.multiSignature?.write
    const { data, isLoading, refetch } = useGetQuestions()
    const { data: resultResponsesData, refetch: refetchResultResponses, isLoading: getResultsLoading } = useGetResultResponses()


    const actionModalInitial = {
        open: false,
        item: null,
        action: null
    }
    const [actionModal, setActionModal] = useState(actionModalInitial)
    const closeActionModal = () => setActionModal(actionModalInitial)


    const deleteModalInitial = {
        open: false,
        item: null
    }
    const [deleteModal, setDeleteModal] = useState(deleteModalInitial)
    const closeDeleteModal = () => setDeleteModal(deleteModalInitial)

    const detailsModalInitial = {
        open: false,
        item: null
    }
    const [detailsModal, setDetailsModal] = useState(detailsModalInitial)
    const closeDetailsModal = () => setDetailsModal(detailsModalInitial)

    const definedAModalInitial = {
        open: false,
        item: null
    }
    const [definedAModal, setDefineAModal] = useState(definedAModalInitial)
    const closeDefineAModal = () => setDefineAModal(definedAModalInitial)

    const editQModalInitial = {
        open: false,
        item: null
    }
    const [editQModal, setEditQModal] = useState(editQModalInitial)
    const closeEditQModal = () => setEditQModal(editQModalInitial)


    const resultRModalInitial = {
        open: false,
        item: null
    }
    const [resultRModal, setResultRModal] = useState(resultRModalInitial)
    const closeResultRModal = () => setResultRModal(resultRModalInitial)

    const { mutate: storeResultMutation, isLoading: storeResultLoading } = useStoreResultResponses(() => {
        closeResultRModal()
        refetchResultResponses()
    })


    const { mutate: updateQMutation } = useUpdateQuestion(() => {
        closeEditQModal()
        refetch()
    })
    const onSubmitUpdateQ = (data) => {
        updateQMutation(data)
    }

    const { mutate: defineAMutation } = useDefineAnswers(() => {
        closeDefineAModal()
        refetch()
    })
    const onSubmitAnswers = (data) => {
        defineAMutation({ responses: data })
    }

    const onDeleteClicked = (item) => {
        setDeleteModal({
            open: true,
            item
        })
    }

    const onDetailsClicked = (item) => {
        setDetailsModal({
            open: true,
            item
        })

    }

    const { mutate: deleteQMutation } = useDeleteQuestion(closeDeleteModal)

    const onSubmitDelete = () => {
        deleteQMutation(deleteModal.item.id);
    }

    const [createQModal, setCreateQModal] = useState({ open: false })
    const closeCreateQModal = () => setCreateQModal({ open: false })

    const onCreateQSuccess = () => {
        closeCreateQModal()
        refetch()
    }

    const { mutate: createQMutation } = useCreateQuestions(onCreateQSuccess)
    const onSubmitCreateQuestion = (data) => {
        createQMutation(data);
    }

    const { mutate: deleteAMutation } = useDeleteResponse(refetch)

    const onOptionClicked = (idx, item) => {
        if (idx === 1) {
            setDefineAModal({
                open: true,
                item
            })
        } else if (idx === 0) {
            setEditQModal({
                open: true,
                item
            })
        }
    }


    return {
        hasWriteAccess,
        onOptionClicked,
        onDeleteClicked,
        onDetailsClicked,
        detailsModal,
        definedAModal,
        closeDefineAModal,
        actionModal,
        closeDetailsModal,
        closeActionModal,
        deleteModal,
        closeDeleteModal,
        onSubmitDelete,
        onSubmitAnswers,
        onSubmitCreateQuestion,
        createQModal,
        closeCreateQModal,
        setCreateQModal,
        resultResponse: {
            resultRModal,
            setResultRModal,
            closeResultRModal,
            storeResultMutation,
            resultResponsesData,
            storeResultLoading,
            getResultsLoading
        },
        deleteAnswer: {
            deleteAMutation
        },
        editQuestion: {
            onSubmitUpdateQ,
            closeEditQModal,
            editQModal
        },
        getQuestions: {
            data, isLoading, refetch
        }
    }
}


export default useQuestions
