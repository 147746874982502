import React from 'react'
import QuestionsTable from '../../components/main/questions/QuestionsTable'
import { PageCard } from '../../styles/CommonStyles'
import ListLayout from '../../components/layout/main/ListLayout'

const QA = () => {


    return (
        <ListLayout>
            <PageCard>
                <QuestionsTable />
            </PageCard>
        </ListLayout>
    )
}

export default QA