import { Suspense } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from "react-router-dom"

import GlobalLoading from "../components/common/loading/GlobalLoading";
import Login from "./Login";
import { useSetAxiosHeaders } from "../core/hooks/common/useSettings";
import MainLayout from "../components/layout/main/MainLayout";
import AclContextProvider from "../core/contexts/acl";
import { useMainContext } from "../core/contexts/main";
import QA from "./QA";



const Index = () => {

    // setting headers
    useSetAxiosHeaders()
    const { token } = useMainContext()

    return (
        <AclContextProvider>
            <Router basename={process.env.PUBLIC_URL}>
                <Suspense fallback={<GlobalLoading />}>
                    <MainLayout>
                        {!!token ?
                            <Routes>
                                {routes.map(route => {

                                    const { path, Component } = route
                                    return <Route key={path} path={path} element={<Component />} />
                                })}
                                <Route path={'*'} element={<Navigate to="/" replace />} />
                            </Routes>
                            :
                            <Routes>
                                <Route key={'login'} path={'/login'} element={<Login />} />
                                <Route path={'*'} element={<Navigate to="/login" replace />} />
                            </Routes>
                        }

                    </MainLayout>
                </Suspense>
            </Router>
        </AclContextProvider>
    )
}


export const routes = [
    { path: '/', Component: QA },
    // // { path: '/login', Component: Login },
    // { path: '/profile', Component: Profile },

    // { path: '/users', Component: Users },
    // { path: '/users/:id', Component: UserDetails },
    // { path: '/users/:id/:tab', Component: UserDetails },
    // { path: '/users/:id/:tab/:detailId', Component: UserSubDetails },
    // { path: '/users/add-user', Component: AddUser },

    // { path: '/files', Component: Files },

    // { path: '/cartable', Component: Cartable },
    // { path: '/cartable/:id', Component: CartableDetails },

    // { path: '/bank-accounts', Component: BankAccounts },

    // { path: '/reports', Component: Reports },

    // { path: '/reports/orders', Component: AllOrders },
    // { path: '/reports/orders/:detailId', Component: OrdersDetail },

    // { path: '/reports/payments', Component: Payments },

    // { path: '/reports/wages-list', Component: WagesList },
    // { path: '/reports/wages-list/:id', Component: WagesTrace },
    // { path: '/reports/wages-list/:id/:detailId', Component: TraceDetails },

    // { path: '/reports/affiliate-overview', Component: Affiliates },
    // { path: '/reports/affiliate-overview/:id', Component: AffiliateReport },
    // { path: '/reports/affiliate-overview/:id/:id', Component: TraceDetails },

    // { path: '/reports/coin-transactions', Component: CoinTransaction },
    // { path: '/reports/coin-transactions/:detailId', Component: CoinTransactionDetails },

    // { path: '/reports/bank-transactions', Component: BankTransaction },
    // { path: '/reports/bank-transactions/:detailId', Component: BankTransactionDetails },

    // { path: '/reports/addresses', Component: Addresses },
    // { path: '/reports/transfers', Component: Transfers },

    // { path: '/setting', Component: Settings },

    // { path: '/setting/tokens', Component: Tokens },
    // { path: '/setting/tokens/:id', Component: TokenDetails },
    // { path: '/setting/tokens/operation', Component: TokenOperation },

    // { path: '/setting/wages', Component: Fiat },

    // { path: '/setting/otc', Component: Otc },

    // { path: '/setting/pairs', Component: Pairs },
    // { path: '/setting/roles', Component: Roles },
    // { path: '/setting/robots', Component: Robots },
    // { path: '/setting/user-levels', Component: UserLevels },
    // { path: '/setting/available-coins', Component: AvailableCoins },
    // { path: '/setting/general', Component: General },
    // { path: '/setting/coin-list', Component: CoinList },
    // { path: '/setting/transfer', Component: TransferSettings },

    // { path: '/fullnode', Component: Fullnode },
    // { path: '/fullnode/:id', Component: FullnodeDetails },

]


export default Index;
