import styled, { css } from "styled-components";
import { useEffect, useState } from "react";
import Text from "../../../core/utils/Text";
import { useRunAfterUpdate } from "../../../core/hooks/common/useRunAfterUpdate"
import { onInputValueChangeUtil } from "../../../core/utils/onInputValueChange"
import { DText, Flex } from "../../../styles/CommonStyles";
import { p2e, stringToNumber } from "../../../core/utils/common";

const Input = (props) => {

  const {
    value,
    onInputChange,
    label,
    width,
    height,
    valid,
    error,
    disabled,
    number,
    direction,
    normal,
    padding,
    margin,
    currency = null,
    size = 'big',
    code = false
  } = props

  const runAfterUpdate = useRunAfterUpdate()

  const STATUS = {
    NORMAL: 'normal',
    ERROR: 'error',
    VALID: 'valid'
  }

  const [status, setStatus] = useState()

  const [animate, setAnimate] = useState(!!props.placeholder)

  const onValueChange = (e) => {

    let value = e?.target?.value
    if (number) {
      value = onInputValueChangeUtil(e, runAfterUpdate)
    }
    if (code) {
      value = p2e(value)
      value = value.replace(/[^\d.,]/g, '')
    }
    onInputChange(value)
  }

  useEffect(() => {

    if ((value || error) && valid === false) setStatus(STATUS.ERROR)
    else if (value && valid) setStatus(STATUS.VALID)
    else setStatus(STATUS.NORMAL)

  }, [value, valid])


  return (
    <InputWrapper
      {...props}
    >
      <InputBody
        size={size}
        width={width}
        height={height}
        onFocus={() => setAnimate(true)}
        onBlur={() => setAnimate(!!props.placeholder)}
        animate={animate}
        status={status}
        value={value}
        normal={normal}
      >

        <Label
          size={size}
          animate={animate}
          status={status}
          normal={normal}
          value={value}
        >
          <Text tid={label} />
        </Label>
        <Flex fw>
          <TInput
            {...props}
            key={label}
            size={size}
            value={value}
            onChange={onValueChange}
            disabled={disabled}
            dir={direction}
          />
          {currency &&
            <Currency>
              {currency.toUpperCase()}
            </Currency>
          }

        </Flex>

        {(error && !valid) &&
          <Error>
            <Text tid={error} />
          </Error>
        }

      </InputBody>
    </InputWrapper>

  )
}


const InputWrapper = styled.div`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  padding: ${props => props.padding || '4px'};
  background-color: inherit;
  margin: ${props => props.margin ? props.margin : '5px 0 20px 0'};
  min-width: ${props => props.minWidth && props.minWidth};
  ${props => props.number && css`
    direction: ltr;
  `};
`


const InputBody = styled.div`
  width: 100%;
  height: 100%;
  min-height: ${props => props.size === 'big' ? '50px' : '42px'};
  position: relative;
  border: 1px solid ${props => props.theme.color}20;
  border-radius: 4px;
  z-index: 2;
  background-color: inherit;
  
  ${props => props.animate && css`
    border: 1px solid ${props => props.theme.active};
  `};

  ${props => props.status === 'valid' && css`
    border: 1px solid ${props => props.theme.mainGreen};
  `};

  ${props => props.status === 'error' && css`
    border: 1px solid ${props => props.theme.mainRed}90;
  `};

  ${props => (props.status === 'normal' && props.animate) && css`
    border: 1px solid ${props => props.theme.active};
  `};
  
  ${props => props.normal && css`
    border: 1px solid ${props => props.theme.color}20;
  `};

`


const TInput = styled.input`
  width: 100%;
  height: 100%;
  min-height: ${props => props.size === 'big' ? '50px' : '42px'};
  padding: 6px 12px;
  outline: none;
  border: none;
  background-color: transparent;
  color: ${props => props.theme.color};
  letter-spacing: 1.1px;
  font-size: 0.9rem;
  position: relative;
  z-index: 2;
  direction: ${props => props.dir && props.dir};
  font-family: 'Vazir';

  ${props => props.size === 'small' && css`
    font-size: 0.8rem;
  `};
`


const Label = styled.div`
  position: absolute;
  font-size: 0.8rem;
  top: calc(50% - 0.6rem);
  background-color: inherit;
  padding: 0 5px;
  letter-spacing: 1.2px;
  
  ${props => props.theme.english && css`
      left: 10px;
  `};

  ${props => !props.theme.english && css`
      right: 10px;
  `};

  color: ${props => props.theme.color};

  ${props => props.animate && css`
    color: ${props => props.theme.active};
  `};
  
  ${props => (props.value || props.value === 0 || props.animate) && css`
    top: -22%;
    font-size: 0.8rem;
    z-index: 10;
  `};

  ${props => props.status === 'valid' && css`
    color: ${props => props.theme.mainGreen};
  `};

  ${props => props.status === 'error' && css`
    color: ${props => props.theme.mainRed}90;
  `};

  ${props => props.normal && css`
    color: ${props => props.theme.color};
  `};
  
  transition: all 0.3s;
  z-index: 1;

  ${props => props.size === 'small' && css`
    font-size: 0.7rem;
  `};
`

const Error = styled.div`
  position: absolute;
  bottom: -20px;
  color: ${props => props.theme.mainRed}90;
  font-size: 0.7rem;
  ${props => props.theme.english && css`
      left: 10px;
  `};

  ${props => !props.theme.english && css`
      right: 10px;
  `};
`


const Currency = styled.div`
  width: 48px;
  height: 100%;
  border-left: 1px solid ${props => props.theme.color}20;
  font-size: 0.8rem;
  color: ${props => props.theme.color};
  display: flex;
  justify-content: center;
  align-items: center;
`


export default Input;
