import { useState } from "react"
import { useQueryContext } from "../../core/contexts/query"
import Text from "../../core/utils/Text"
import { Background, CFlex, CMargin, Decoration, DText, Flex } from "../../styles/CommonStyles"
import BasicModalActionButton from "../common/buttons/BasicModalActionButton"
import Input from "../common/input/Input"
import { useEffect } from "react"
import ModalLayout from "../layout/main/ModalLayout"
import EditorConvertToHTML, { getPlainText } from "./RichTextModal"


const CreateQuestionModal = ({
    onSubmit,
    onCancel,
    editQ
}) => {
    const [richTextModal, setRichTextModal] = useState({ open: false });

    const { setToast } = useQueryContext()

    const initialState = {
        text: "",
        order: ""
    }
    const [question, setQuestion] = useState(initialState)

    const onQuestionInputChange = (v, type) => {
        setQuestion(state => ({ ...state, [type]: v }))
    }

    const onSubmitClicked = () => {
        const valid = question.text
        if (valid) onSubmit(question)
        else setToast({
            isError: true, show: true,
            message: 'fill-input-errors'
        })
    }

    useEffect(() => {
        if (editQ) {
            setQuestion({
                id: editQ.id,
                text: editQ.text,
                order: editQ.order

            })
        }
    }, [editQ])

    return (
        <CFlex fw align='flex-start'>
            <Background>
                <Flex>
                    <Decoration />
                    <DText main>
                        <Text tid={`add-question`} />
                    </DText>
                </Flex>
                <CMargin margin='10px' />
                <Input
                    value={getPlainText(question.text)}
                    label={'question'}
                    onInputChange={(v) => onQuestionInputChange(v, 'text')}
                    onClick={() => {
                        setRichTextModal({ open: true });
                    }}
                    normal
                />
                <Input
                    value={question.order}
                    label={'order'}
                    onInputChange={(v) => onQuestionInputChange(v, 'order')}

                    number
                    normal
                />
                <BasicModalActionButton
                    onSubmit={onSubmitClicked}
                    onCancel={onCancel}
                />
            </Background>
            <ModalLayout
                width={"680px"}
                open={richTextModal.open}
                onClose={() => setRichTextModal({ open: false })}
            >
                <EditorConvertToHTML
                    onClose={() => setRichTextModal({ open: false })}
                    setResults={(q) =>
                        onQuestionInputChange(q, 'text')
                    }
                    result={question.text || ""}
                />
            </ModalLayout>
        </CFlex>
    )
}


export default CreateQuestionModal
