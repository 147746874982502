import TableLayout from "../../layout/main/TableLayout";
import { Column, DText, Flex, Relative, Row } from "../../../styles/CommonStyles";
import DateTime from "../../common/utils/DateTime";
import Operation from "../../common/utils/Operation";
import { questionsOperations } from "../../../core/constants/operation";
import ModalLayout from "../../layout/main/ModalLayout";
import BasicModal from "../../modals/BasicModal";
import { questionsAttributes } from "../../../core/constants/headers";
import useQuestions from "../../../core/hooks/main/questions/useQuestions";
import DefineAnswersModal from "../../modals/DefineAnswersModal";
import GlobalLoading from "../../common/loading/GlobalLoading";
import CreateQuestionModal from "../../modals/CreateQuestionModal";
import CreateBtn from "../../common/buttons/CreateButton";
import StoreResultResponsesModal from "../../modals/StoreResultResponses";


const QuestionsTable = () => {
    const { cs, headers } = questionsAttributes

    const {
        hasWriteAccess,
        onOptionClicked,
        onDetailsClicked,
        onDeleteClicked,
        closeDetailsModal,
        deleteModal,
        closeDeleteModal,
        onSubmitDelete,
        onSubmitAnswers,
        detailsModal,
        definedAModal,
        closeDefineAModal,
        deleteLoading,
        onSubmitCreateQuestion,
        createQModal,
        getQuestions,
        setCreateQModal,
        closeCreateQModal,
        editQuestion,
        deleteAnswer,
        resultResponse
    } = useQuestions()


    return getQuestions.isLoading ? <GlobalLoading /> : (
        <>
            <Flex fw justify='space-between'>
                <CreateBtn title='define-final-response' onClick={() => { resultResponse.setResultRModal({ open: true }) }} />
                <CreateBtn title='add-question' onClick={() => { setCreateQModal({ open: true }) }} />
            </Flex>
            <TableLayout
                hasPagination={false}
                data={getQuestions.data}
                headers={headers}
                cs={cs}
                hasWriteAccess
            >
                {getQuestions.data?.map((item, idx) => (
                    <Relative>
                        <Row
                            key={item._id}
                            cs={cs}
                            index={idx}
                        >
                            <Column>
                                {idx + 1}
                            </Column>
                            <Column>
                                <DText style={{ direction: 'ltr' }}>
                                    {item.text}
                                </DText>
                            </Column>
                            <Column>
                                {item.order}
                            </Column>
                            <Column center>
                                <DateTime dt={item.created_at} />
                            </Column>
                        </Row>
                        <Column operation>
                            <Operation
                                options={questionsOperations}
                                onOptionClicked={(idx) => onOptionClicked(idx, item)}
                                onDeleteClicked={() => onDeleteClicked(item)}
                                onDetailsClicked={() => onDetailsClicked(item)}
                                hasWriteAccess
                                hasDelete
                                deleteTooltip={'delete-question'}
                                hasDetails={false}
                            />
                        </Column>
                    </Relative>
                ))}
                <ModalLayout
                    width={'580px'}
                    open={detailsModal.open}
                    onClose={closeDetailsModal}
                >
                    {/* <BasicModal onSubmit={onSubmitDelete} onClose={closeDeleteModal} /> */}
                </ModalLayout>
                <ModalLayout
                    width={'580px'}
                    open={deleteModal.open}
                    onClose={closeDeleteModal}
                >
                    <BasicModal onSubmit={onSubmitDelete} onClose={closeDeleteModal} />
                </ModalLayout>
                <ModalLayout
                    width={'580px'}
                    open={definedAModal.open}
                    onClose={closeDefineAModal}
                >
                    <DefineAnswersModal onDeleteAnswer={deleteAnswer.deleteAMutation} question={definedAModal.item} onSubmit={onSubmitAnswers} onCancel={closeDefineAModal} />
                </ModalLayout>
                <ModalLayout
                    width={'580px'}
                    open={createQModal.open}
                    onClose={closeCreateQModal}
                >
                    <CreateQuestionModal onSubmit={onSubmitCreateQuestion} onCancel={closeCreateQModal} />
                </ModalLayout>
                <ModalLayout
                    width={'580px'}
                    open={editQuestion.editQModal.open}
                    onClose={editQuestion.closeEditQModal}
                >
                    <CreateQuestionModal editQ={editQuestion.editQModal.item} onSubmit={editQuestion.onSubmitUpdateQ} onCancel={editQuestion.closeEditQModal} />
                </ModalLayout>
                <ModalLayout
                    width={'580px'}
                    open={resultResponse.resultRModal.open}
                    onClose={resultResponse.closeResultRModal}
                >
                    {
                        resultResponse.resultResponsesData &&
                        <StoreResultResponsesModal storeResultLoading={resultResponse.storeResultLoading || resultResponse.getResultsLoading} defaultData={resultResponse.resultResponsesData.data} onSubmit={resultResponse.storeResultMutation} onCancel={resultResponse.closeResultRModal} />
                    }
                </ModalLayout>
            </TableLayout>
        </>
    )
}


export default QuestionsTable
