import { DText, Flex } from "../../../styles/CommonStyles";
import { DownloadIcon } from "../../../styles/main/setting";
import Text from "../../../core/utils/Text";
import styled from "styled-components";
import { BarLoader } from "react-spinners";
import { BiPlusCircle } from "react-icons/bi";


const CreateBtn = (props) => {

    return (
        <Wrapper
            {...props}
        >
            <Flex fw fh>
                {props.loading ?
                    <BarLoader
                        color={'#ffc800'}
                        width={'52px'}
                    />
                    :
                    <>
                        <BiPlusCircle />
                        <DText
                            style={{ margin: '0 5px' }}
                            fontSize={'ss'}
                            main
                        >
                            <Text tid={props.title} />
                        </DText>
                    </>
                }
            </Flex>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  height: 38px;
  margin: 4px;
  transition: all 0.2s;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.05);
  min-width: 152px;
  padding: 0 12px;
  background-color: ${props => props.theme.secondaryBg};
  border: 1px solid ${props => props.theme.color}10;
`

export default CreateBtn
