import { Background, Flex } from "../../../styles/CommonStyles";
import { ReactComponent as Error } from "../../../assets/illustrations/server-error.svg";
import { NoAccessDesc, NoAccessText } from "../../../styles/layout";
import Text from "../../../core/utils/Text";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const ServerDown = ({ callback }) => {

    const navigate = useNavigate()


    return (
        <Flex fw height={'100vh'}>
            <Background bg={'mainBg'}>
                <Error width={'600px'} style={{ maxWidth: '100%' }} height={'300px'} />
                <NoAccessText>
                    <Text tid={'server-error'} />
                </NoAccessText>
                <NoAccessDesc>
                    <Text tid={'moving-to-profile'} />
                </NoAccessDesc>
            </Background>
        </Flex>
    )
}

export default ServerDown
