import { useMutation, useQuery } from "react-query";
import { normalFetch } from '../../fetch-api/get'
import { postApi } from "../../fetch-api/post";
import { deleteApi } from "../../fetch-api/delete";
import { putApi } from "../../fetch-api/put";
import { patchApi } from "../../fetch-api/patchApi";

const Q_KEYS = {
    GET_Q: 'getQ',
    CREATE_Q: 'createQ',
    DEFINE_Q: 'defineQ',
    DELETE_Q: 'deleteQ',
    UPDATE_Q: 'updateQ',
    DELETE_A: 'deleteA',
    RESULT_R: 'resultR',
}

const useGetQuestions = () => {
    return useQuery(
        Q_KEYS.GET_Q, () => normalFetch('survey-apis/questions'),
        {
            select: (res) => res?.data?.data
        }
    )
}

const useCreateQuestions = (onSuccess) => {

    return useMutation(
        Q_KEYS.CREATE_Q, (data) => postApi(data, 'survey-apis/questions'),
        {
            onSuccess: (res) => {
                onSuccess()
            }
        }
    )
}

const useDefineAnswers = (onSuccess) => {

    return useMutation(
        Q_KEYS.DEFINE_Q, (data) => postApi(data, 'survey-apis/admin/user-responses'),
        {
            onSuccess: (res) => {
                onSuccess()
            }
        }
    )
}

const useDeleteQuestion = (onSuccess) => {
    const { refetch } = useGetQuestions()
    return useMutation(
        Q_KEYS.DELETE_Q, (id) => deleteApi(`survey-apis/questions/${id}`),
        {
            onSuccess: (res) => {
                refetch()
                onSuccess()
            }
        }
    )
}

const useUpdateQuestion = (onSuccess) => {
    return useMutation(
        Q_KEYS.UPDATE_Q, (data) => patchApi({ text: data.text, order: data.order }, `survey-apis/questions/${data.id}`),
        {
            onSuccess: (res) => {
                onSuccess()
            }
        }
    )
}

const useDeleteResponse = (onSuccess) => {
    return useMutation(
        Q_KEYS.DELETE_A, (id) => deleteApi(`survey-apis/admin/user-responses/${id}`),
        {
            onSuccess: (res) => {
                onSuccess()
            }
        }
    )
}

const useGetResultResponses = (onSuccess) => {
    return useQuery(
        Q_KEYS.RESULT_R, (data) => normalFetch(`survey-apis/admin/survey-result-responses`),
        {
            onSuccess: (res) => {
                onSuccess()
            }
        }
    )
}

const useStoreResultResponses = (onSuccess) => {
    return useMutation(
        Q_KEYS.RESULT_R, (data) => postApi({ responses: data }, `survey-apis/admin/survey-result-responses`),
        {
            onSuccess: (res) => {
                onSuccess()
            }
        }
    )
}

const useEditResultResponses = () => {
    const { refetch } = useGetResultResponses()
    return useMutation(
        Q_KEYS.RESULT_R, (data) => patchApi({ ...data, }, `survey-apis/admin/survey-result-responses/${data.id}`),
        {
            onSuccess: (res) => {
                refetch()
            }
        }
    )
}

const useDeleteResultResponse = () => {
    const { refetch } = useGetResultResponses()
    return useMutation(
        Q_KEYS.RESULT_R, (id) => deleteApi(`survey-apis/admin/survey-result-responses/${id}`),
        {
            onSuccess: (res) => {
                refetch()
            }
        }
    )
}


export {
    useGetQuestions,
    useCreateQuestions,
    useDefineAnswers,
    useDeleteQuestion,
    useUpdateQuestion,
    useDeleteResponse,
    useStoreResultResponses,
    useGetResultResponses,
    useEditResultResponses,
    useDeleteResultResponse
}
