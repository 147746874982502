import { useEffect, useState } from "react";
import { initialAcl, superAdmin } from "../../constants/acl";



const useAccessLevels = () => {

    const [loading, setLoading] = useState(true)
    const [perms, setPerms] = useState(initialAcl)

    /**
     * user role
     */
    const [role, setRole] = useState(null)

    /**
     * getting current user role details - (permissions)
     */
    // const { data: permissions, refetch: getRole } = useGetRoleQuery(role)

    const permit = () => {

        if ([].length) {
            let temp = initialAcl
            [1].forEach(perm => {
                const activity = perm.activity
                const scope = perm.scope

                if (scope in temp) {
                    temp[scope].read = activity.read
                    temp[scope].write = activity.write
                }
            })
            setLoading(false)
            setPerms(temp)
        }
    }

    useEffect(permit, [])

    /**
     * profile
     */
    const onProfileSuccess = (res) => {
        setRole(res.role)
    }

    useEffect(() => {
        if (role) {
            if (role !== 'admin') return
            else {
                setLoading(false)
                setPerms(superAdmin)
            }
        }
    }, [role])

    const reset = () => setRole(null)

    return {
        permissions: perms,
        loading,
        reset,
        setRole
    }
}

export {
    useAccessLevels
}
