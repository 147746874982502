import { useEffect } from "react";




const useNetwork = () => {


    useEffect(() => {
        const interval = setInterval(
            () => { },
            30000
        )

        return () => interval && clearInterval(interval)
    }, [])

    return false
}


export default useNetwork;
