import { useEffect, useState } from "react";
import { useQueryContext } from "../../core/contexts/query";
import Text from "../../core/utils/Text";
import { Background, CFlex, CMargin, Decoration, DText, Flex } from "../../styles/CommonStyles";
import BasicModalActionButton from "../common/buttons/BasicModalActionButton";
import Input from "../common/input/Input";
import { BiCheck, BiPlus, BiX } from "react-icons/bi";
import { useDeleteResultResponse, useEditResultResponses } from "../../core/services/react-query/questions";
import ModalLayout from "../layout/main/ModalLayout";
import EditorConvertToHTML, { getPlainText } from "./RichTextModal";
import { SiProgress } from "react-icons/si";
import { ScaleLoader } from "react-spinners";
import styled from "styled-components";

const StoreResultResponsesModal = ({ onSubmit, onCancel, defaultData, storeResultLoading }) => {
    const { setToast } = useQueryContext();
    const { mutate, isLoading: deleteIsLoading } = useDeleteResultResponse();
    const { mutate: editMutate, isSuccess, isLoading } = useEditResultResponses();
    const [resultResponses, setResults] = useState([]);
    const [defaultResultResponses, setDefaultResults] = useState([]);
    const [richTextModal, setRichTextModal] = useState({ open: false, index: null, isNew: false });
    const addResultResponse = () => {
        setResults((prev) => [
            ...prev,
            { id: "", min_score: 0, max_score: 0, response: "", isEdited: false },
        ]);
    };

    const editResultResponse = (index) => {
        const updatedResponse = defaultResultResponses[index];
        if (updatedResponse.isEdited) {
            editMutate(updatedResponse, {
                onSuccess: () => {
                    setDefaultResults((prev) =>
                        prev.map((res, i) =>
                            i === index ? { ...res, isEdited: false } : res
                        )
                    );
                    setToast({
                        isError: false,
                        show: true,
                        message: "Edit saved successfully",
                    });
                },
                onError: () => {
                    setToast({
                        isError: true,
                        show: true,
                        message: "Failed to save edit",
                    });
                },
            });
        }
    };

    const removeResultResponse = (index, type) => {
        if (type === "new") {
            setResults((prev) => prev.filter((_, i) => i !== index));
        } else if (defaultResultResponses.length > 1) {
            if (defaultData && defaultResultResponses[index].id) {
                mutate(defaultResultResponses[index].id, {
                    onSuccess: () => {
                        setDefaultResults((prev) => prev.filter((_, i) => i !== index));
                        setToast({
                            isError: false,
                            show: true,
                            message: "Successfully deleted",
                        });
                    },
                    onError: () => {
                        setToast({
                            isError: true,
                            show: true,
                            message: "Failed to delete!",
                        });
                    },
                });
            }

        }
    };

    const onResultChange = (value, index, field, type) => {
        if (type === "new") {
            setResults((prev) =>
                prev.map((result, i) =>
                    i === index ? { ...result, [field]: value } : result
                )
            );
        } else {
            setDefaultResults((prev) =>
                prev.map((result, i) =>
                    i === index
                        ? {
                            ...result,
                            [field]: value,
                            isEdited: true,
                        }
                        : result
                )
            );
        }
    };

    const onSubmitClicked = () => {
        if (resultResponses.length) {
            const valid = resultResponses.every(
                (result) =>
                    result.min_score && result.max_score && result.response
            );
            if (valid) onSubmit(resultResponses);
            else
                setToast({
                    isError: true,
                    show: true,
                    message: "fill-input-errors",
                });
        } else onCancel()
    };

    const setDefaultData = () => {
        const defaultResults = defaultData.map((data) => ({
            id: data.id,
            min_score: data.min_score,
            max_score: data.max_score,
            response: data.response,
            isEdited: false,
        }));
        setDefaultResults(defaultResults);
    }

    useEffect(() => {
        if (defaultData.length) {
            setDefaultData()
        }
    }, [defaultData,]);

    useEffect(() => {
        if (isSuccess) {
            setDefaultData()
        }
    }, [isSuccess,]);

    return (
        <>
            <CFlex fw align="flex-start">
                <Background>
                    <Flex>
                        <Decoration />
                        <DText main>
                            <Text tid={`define-final-response`} />
                        </DText>
                    </Flex>
                    <CMargin margin="10px" />
                    <Wrapper id="TEST">
                        {defaultResultResponses.map((result, index) => (
                            <div key={index} style={{ marginBottom: "15px", width: "100%" }}>
                                <Flex align="center" justify="flex-start" margin="0" fw>
                                    {!deleteIsLoading ? defaultResultResponses.length > 1 && (
                                        <div
                                            style={{ cursor: "pointer" }}
                                            onClick={() => removeResultResponse(index, "default")}
                                        >
                                            <BiX size={24} color="#c33" />
                                        </div>
                                    ) : <ScaleLoader height={16} width={2} color="#37af28" />}
                                    <Input
                                        width="22%"
                                        value={result.min_score}
                                        label={"min-score"}
                                        onInputChange={(v) =>
                                            onResultChange(v, index, "min_score", "default")
                                        }
                                        number
                                        margin="0"
                                    />
                                    <Input
                                        width="22%"
                                        value={result.max_score}
                                        label={"max-score"}
                                        onInputChange={(v) =>
                                            onResultChange(v, index, "max_score", "default")
                                        }
                                        number
                                        margin="0"
                                    />
                                    <Input
                                        width="55%"
                                        value={getPlainText(result.response)}
                                        label={"final-response"}
                                        onClick={() => {
                                            setRichTextModal({ open: true, index: index });
                                        }}
                                        onInputChange={(v) =>
                                            onResultChange(v, index, "response", "default")
                                        }
                                        margin="0"
                                    />
                                    {!isLoading ? result.isEdited && (
                                        <div
                                            style={{ cursor: "pointer" }}
                                            onClick={() => editResultResponse(index)}
                                        >
                                            <BiCheck size={24} color="#37af28" />
                                        </div>
                                    ) : result.isEdited && <ScaleLoader height={16} width={2} color="#37af28" />}
                                </Flex>
                            </div>
                        ))}
                        {resultResponses.map((result, index) => (
                            <div key={index} style={{ marginBottom: "15px", width: '100%' }}>
                                <Flex align="center" justify='flex-start' margin='0' fw>
                                    <div style={{ cursor: 'pointer' }} onClick={() => removeResultResponse(index, 'new')}>
                                        <BiX size={24} color="#c33" />
                                    </div>
                                    <Input
                                        width="22%"
                                        value={result.min_score}
                                        label={"min-score"}
                                        onInputChange={(v) => onResultChange(v, index, "min_score", "new")}
                                        number
                                        margin="0"
                                    />
                                    <Input
                                        width="22%"
                                        value={result.max_score}
                                        label={"max-score"}
                                        onInputChange={(v) => onResultChange(v, index, "max_score", "new")}
                                        number
                                        margin="0"
                                    />
                                    <Input
                                        width="55%"
                                        value={
                                            typeof result.response === "string"
                                                ? getPlainText(result.response)
                                                : result.response
                                        }
                                        label={"final-response"}
                                        onClick={() => {
                                            setRichTextModal({ open: true, index: index, isNew: true })
                                        }}
                                        onInputChange={(v) => onResultChange(v, index, "response", "new")}
                                        margin="0"
                                    />
                                </Flex>
                            </div>
                        ))}
                        <div style={{ cursor: "pointer" }} onClick={addResultResponse}>
                            <BiPlus size={24} color="#43ee00" />
                        </div>
                    </Wrapper>
                    <CMargin margin="10px" />
                    <BasicModalActionButton
                        loading={storeResultLoading}
                        onSubmit={onSubmitClicked}
                        onCancel={onCancel}
                    />
                </Background>
            </CFlex>

            <ModalLayout
                width={"680px"}
                open={richTextModal.open}
                onClose={() => setRichTextModal({ open: false })}
            >
                <EditorConvertToHTML
                    onClose={() => setRichTextModal({ open: false })}
                    setResults={(updatedResponse) =>
                        onResultChange(updatedResponse, richTextModal.index, "response", richTextModal.isNew ? 'new' : 'default')
                    }
                    result={(richTextModal.isNew ? resultResponses : defaultResultResponses)[richTextModal.index]?.response || ""}
                />
            </ModalLayout>
        </>
    );
};

export default StoreResultResponsesModal;


const Wrapper = styled.div`
    height: 100vh;
    max-height: calc(100vh - 20rem);
    overflow-y: auto;
`