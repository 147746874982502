import { useMainContext } from "../core/contexts/main";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginView from "../components/main/login/LoginView";


const Login = () => {

    const navigate = useNavigate()
    const { token } = useMainContext()
    useEffect(() => {
        if (token) navigate('/')
    }, [])

    return (
        <LoginView />
    )
}

export default Login
