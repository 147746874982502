import React from 'react';
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import { useMainContext } from '../../core/contexts/main';
import { CFlex, CMargin } from '../../styles/CommonStyles';
import BasicModalActionButton from '../common/buttons/BasicModalActionButton';


const EditorConvertToHTML = ({ onClose, result, setResults }) => {
    const { theme } = useMainContext();

    const onEditorStateChange = (newResponse) => {
        setResults(newResponse); // Pass the updated response back to parent.
    };

    return (
        <CFlex>
            <ReactQuill
                style={{
                    width: '100%',
                    direction: 'ltr',
                    color: theme === 'light' ? 'black' : 'white',
                }}
                theme="snow"
                value={result}
                onChange={onEditorStateChange}
            />
            <CMargin margin="10px" />
            <BasicModalActionButton
                onSubmit={onClose}
                onCancel={onClose}
            />
        </CFlex>
    );
};


export default EditorConvertToHTML;


export const getPlainText = (htmlContent) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent;
    return tempElement.textContent || tempElement.innerText || "";
};